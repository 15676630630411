import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type {
	TextBoxChangeEvent,
	TextBoxHandle,
} from "@progress/kendo-react-inputs";
import { debounce } from "es-toolkit";
import NProgress from "nprogress";
import { useEffect, useMemo, useRef, useState } from "react";
import { SUBCONTRACTOR_GRID_COLUMNS } from "../../../../common/models/src/lib/constants/grid-column.constants";
import { NOT_AVAILABLE } from "../../../../common/models/src/lib/constants/messages.constants";
import { SUBCONTRACTORS_PAGE_SIZE } from "../../../../common/models/src/lib/constants/subcontractor.constants";
import {
	ModalSize,
	ModalType,
} from "../../../../common/models/src/lib/enums/modal.enums";
import type { ISubcontractorRequestDto } from "../../../../common/models/src/lib/interfaces/subcontractor.interface";
import type { VehicleType } from "../../../../common/models/src/lib/types/vehicle.type";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumGridHeader } from "../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumSearchInput } from "../../../../common/ui/src/lib/components/celerum-input/celerum-input.component";
import { CelerumModal } from "../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { buildFilterQueryString } from "../../../../common/utils/src/lib/helpers/query.helpers";
import { fetchConstraintsAction } from "../../../../constraints/data-access/src/lib/constraints.slice";
import { fetchTrailerTypesAction } from "../../../../trailers/data-access/src/lib/trailer-types.slice";
import { fetchTruckTypesAction } from "../../../../trucks/data-access/src/lib/truck-types.slice";
import {
	clearSubcontractorsAction,
	deleteSubcontractorAction,
	fetchSubcontractorsAction,
} from "../../../data-access/src/lib/subcontractors.slice";
import { SubcontractorForm } from "./components/subcontractor-form/subcontractor-form.component";

export const SubcontractorsFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { data, total, loading } = useAppSelector(
		(state) => state.subcontractors,
	);

	const [showModal, setShowModal] = useState<{
		createOrUpdate: boolean;
		delete: boolean;
	}>({
		createOrUpdate: false,
		delete: false,
	});
	const [selectedSubcontractor, setSelectedSubcontractor] = useState<
		ISubcontractorRequestDto | undefined
	>(undefined);
	const [sort, setSort] = useState<string>("");
	const [page, setPage] = useState<Page>({
		skip: 0,
		take: SUBCONTRACTORS_PAGE_SIZE,
	});
	const [searchFilter, setSearchFilter] = useState<string>("");

	const searchInputRef = useRef<TextBoxHandle>(null);

	const renderedSubcontractors = useMemo(
		() =>
			data.map((subcontractor) => ({
				...subcontractor,
				primaryPhone: subcontractor?.phones?.length
					? subcontractor.phones[0]
					: "",
				primaryEmail: subcontractor?.emails?.length
					? subcontractor.emails[0]
					: "",
				primaryTruckRegistrationNumber: subcontractor?.truckRegistrationNumbers
					?.length
					? subcontractor.truckRegistrationNumbers[0]
					: NOT_AVAILABLE,
				vehicleTypes: (
					subcontractor.truckTypes.concat(
						subcontractor.trailerTypes,
					) as VehicleType[]
				).map((vehicleType) => ({
					...vehicleType,
					uniqueId: `${vehicleType.id}-${vehicleType.name}`,
				})),
			})),
		[data],
	);

	const openCreateModal = () => {
		setShowModal({ ...showModal, createOrUpdate: true });
	};

	const openUpdateModal = (subcontractor: ISubcontractorRequestDto) => {
		setShowModal({ ...showModal, createOrUpdate: true });
		setSelectedSubcontractor(subcontractor);
	};

	const openDeleteModal = (subcontractor: ISubcontractorRequestDto) => {
		setShowModal({ ...showModal, delete: true });
		setSelectedSubcontractor(subcontractor);
	};

	const closeModal = () => {
		setShowModal({ createOrUpdate: false, delete: false });
		setSelectedSubcontractor(undefined);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < data.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (data[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / SUBCONTRACTORS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchSubcontractorsAction,
					payload: { page, pageSize: SUBCONTRACTORS_PAGE_SIZE, sort },
					errorMessage: "Could not fetch subcontractors.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/** Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearSubcontractorsAction());
		dispatchWithNotifications({
			action: fetchSubcontractorsAction,
			payload: { page: 1, pageSize: SUBCONTRACTORS_PAGE_SIZE, sort },
			errorMessage: "Could not sort subcontractors.",
		});
	};

	const handleSearchChange = debounce((e: TextBoxChangeEvent) => {
		setSearchFilter(e.value?.toString() || "");
	}, 300);

	const handleDeleteSubcontractor = () => {
		if (!selectedSubcontractor) return;

		dispatchWithNotifications({
			action: deleteSubcontractorAction,
			payload: selectedSubcontractor.id,
			successMessage: `Subcontractor ${selectedSubcontractor.name} was successfully deleted.`,
			errorMessage: `Could not delete subcontractor ${selectedSubcontractor.name}.`,
		});
		setSelectedSubcontractor(undefined);
	};

	useEffect(() => {
		const filters = buildFilterQueryString(searchFilter, ["name", "notes"]);

		if (searchFilter) {
			dispatchWithNotifications({
				action: fetchSubcontractorsAction,
				payload: { page: 1, pageSize: SUBCONTRACTORS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch subcontractors.",
			});
		} else {
			dispatchWithNotifications({
				action: fetchSubcontractorsAction,
				payload: { page: 1, pageSize: SUBCONTRACTORS_PAGE_SIZE },
				errorMessage: "Could not fetch subcontractors.",
			});
		}
		return () => {
			dispatch(clearSubcontractorsAction());
		};
	}, [dispatch, dispatchWithNotifications, searchFilter]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTrailerTypesAction,
			payload: {},
			errorMessage: "Could not fetch trailer types.",
		});
		dispatchWithNotifications({
			action: fetchTruckTypesAction,
			payload: {},
			errorMessage: "Could not fetch truck types.",
		});
		dispatchWithNotifications({
			action: fetchConstraintsAction,
			payload: {},
			errorMessage: "Could not fetch constraints.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<CelerumGridHeader
				title="Subcontractors"
				numberOfItems={total}
				addButtonName="Add Subcontractor"
				addButtonDisabled={loading}
				handleOpenAddModal={openCreateModal}
			>
				<CelerumSearchInput
					inputRef={searchInputRef}
					setSearchValue={(value: string) => setSearchFilter(value)}
					onChange={(event: TextBoxChangeEvent) => handleSearchChange(event)}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={SUBCONTRACTOR_GRID_COLUMNS}
				data={renderedSubcontractors}
				total={total}
				loading={loading}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleUpdate={openUpdateModal}
				handleDelete={openDeleteModal}
			/>
			<CelerumModal
				title={
					selectedSubcontractor
						? selectedSubcontractor.name
						: "Add New Subcontractor"
				}
				width={ModalSize.Medium}
				toggleDialog={closeModal}
				visible={showModal.createOrUpdate}
			>
				<SubcontractorForm
					formState={selectedSubcontractor}
					onClose={closeModal}
				/>
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Delete}
				subtitle="The subcontractor will be completely removed and cannot be recovered."
				entityName="subcontractor"
				entityProperty="named"
				entityValue={selectedSubcontractor?.name}
				isOpen={showModal.delete}
				handleSubmit={handleDeleteSubcontractor}
				handleClose={closeModal}
			/>
		</>
	);
};
