import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useQuery } from "@tanstack/react-query";
import { sumBy } from "es-toolkit";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";
import type { LoadGridObject } from "../Load";
import { TypedDropDownButton } from "../TypedDropDownButton";
import type { LoadActions } from "../api/JobApi";
import { useAuditDialog } from "../dialogs/useAuditDialog";
import { useLoadFormDialog } from "../dialogs/useLoadFormDialog";
import type { LoadFormWithDTOProps } from "../forms/LoadFormWithDTO";
import { LoadsGrid } from "../grids/LoadsGrid";
import {
	LoadStatus,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	loadStatusNames,
	toCurrency,
	toDatetimeString,
	toasted,
} from "../helpers";
import { useGenericDateRangePicker } from "../helpersReact";

const useFetchData = (rangeValues: SelectionRange) => {
	const _loads = useQuery({
		queryKey: ["jobApi.load.loadV2List", rangeValues.start, rangeValues.end],
		queryFn: () =>
			jobApi.load
				.loadV2List({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
				})
				.then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const loads = useMemo(
		() =>
			_loads.data.map((item) => {
				const startDate = new Date(item.startDate ?? "");
				const endDate = new Date(item.endDate ?? "");
				const load: LoadGridObject = {
					actions: item.actions,
					id: item.id,
					uniqueId: item.uniqueId,
					status: item.status,
					statusString: loadStatusNames[item.status] ?? "",
					assignedTo: item.assignedTo ?? "",
					endDate: endDate,
					loadTypeId: item.loadTypeId,
					assignedToString: item.assignedToName ?? "",
					endDateString: toDatetimeString(endDate),
					endLocationId: item.endLocationId ?? undefined,
					endLocationString: item.endLocationName ?? "",
					loadTypeString: item.loadTypeName ?? "",
					notes: item.notes ?? "",
					startDate,
					startDateString: toDatetimeString(startDate),
					startLocationId: item.startLocationId,
					startLocationString: item.startLocationName ?? "",
					trailerString: item.trailerName ?? "",
					subcontractorName: item.subcontractorName ?? "",
					truckString: item.truckName ?? "",
					driverString: item.driverName ?? "",
					profit: item.profit ?? 0,
					profitString: `${(item.profit ?? 0).toFixed(2)}%`,
					cost: item.cost ?? undefined,
					revenue: item.revenue ?? undefined,
					sharedCost: item.sharedCost ?? undefined,
					currencyCode: item.currencyCode ?? undefined,
				};
				return load;
			}),
		[_loads.data],
	);
	return { loads, retry: _loads.refetch, loading: _loads.isFetching };
};
const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] = useGenericDateRangePicker("Loads");
	return [dateRangeEl, rangeValues] as const;
};

type Action = keyof LoadActions | "resume";
type ActionItem = { text?: string; action: Action; data: LoadGridObject };

const useHandleAction = (
	retry: () => void,
	showEditLoad: (props?: LoadFormWithDTOProps) => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();

	return useCallback(
		(item: ActionItem): undefined => {
			switch (item.action) {
				case "allowViewDetails":
					navigate(`/loads/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "allowEdit":
					showEditLoad({
						id: item.data.id,
						onSubmit: () => {
							retry();
							showEditLoad();
						},
					});
					break;
				case "resume":
					toasted(
						jobApi.load.loadChangeStatusResumeCreate(item.data.id).then(retry),
						"Resuming load",
					);
					break;
				case "allowPause":
					toasted(
						jobApi.load.loadChangeStatusPauseCreate(item.data.id).then(retry),
						"Pausing load",
					);
					break;
				case "allowCancel":
					toasted(
						jobApi.load.loadChangeStatusCancelCreate(item.data.id).then(retry),
						"Cancelling load",
					);
					break;
				case "allowGenerateManifest":
					toasted(
						jobApi.load
							.loadReportManifestDetail(item.data.id, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(retry),
						"Generating manifest",
					);
					break;
				case "allowViewClearCustomsDoc":
					// purposefully not implemented
					break;
				case "allowGenerateAllDocuments":
					toasted(
						jobApi.load
							.loadReportDetail(item.data.id, 1, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(retry),
						"Generating all documents",
					);
					break;
				case "allowGenerateManifestAndDeliveryTickets":
					toasted(
						jobApi.load
							.loadReportDetail(item.data.id, 2, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(retry),
						"Generating manifest and delivery tickets",
					);
					break;
				case "allowGenerateManifestAndCmr":
					toasted(
						jobApi.load
							.loadReportDetail(item.data.id, 3, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.then(retry),
						"Generating manifest and CMRs",
					);
					break;
				case "allowAudit":
					showAuditFor(item.data.id);
					break;
				case "allowResume":
					toasted(
						jobApi.load.loadChangeStatusResumeCreate(item.data.id).then(retry),
						"Resuming load",
					);
					break;
				default:
					return item.action;
			}
		},
		[location.pathname, navigate, showEditLoad, showAuditFor, retry],
	);
};

type StatusItem = {
	text?: string;
	action: Action;
	data: LoadGridObject;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} />
);

const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<LoadGridObject>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.actions.allowEdit) {
						items.push({
							text: "Edit",
							action: "allowEdit",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowPause) {
						items.push({
							text: "Pause",
							action: "allowPause",
							data: dataItem,
						});
					}
					if (dataItem.status === LoadStatus.Paused) {
						items.push({
							text: "Resume",
							action: "resume",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowCancel) {
						items.push({
							text: "Cancel",
							action: "allowCancel",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateManifest) {
						items.push({
							text: "Generate Manifest",
							action: "allowGenerateManifest",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateAllDocuments) {
						items.push({
							text: "Print All Documents",
							action: "allowGenerateAllDocuments",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateManifestAndDeliveryTickets) {
						items.push({
							text: "Print Manifest and Delivery Tickets",
							action: "allowGenerateManifestAndDeliveryTickets",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateManifestAndCmr) {
						items.push({
							text: "Print Manifest and CMRs",
							action: "allowGenerateManifestAndCmr",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowAudit) {
						items.push({
							text: "Audit",
							action: "allowAudit",
							data: dataItem,
						});
					}
					return (
						<td>
							<Button
								size="small"
								icon="eye"
								onClick={() =>
									handleAction({ action: "allowViewDetails", data: dataItem })
								}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};

export const LoadsPage2 = () => {
	const [showAuditFor, auditDialog] = useAuditDialog(TableNameType.Load);
	const [showLoadForm, loadFormDialog] = useLoadFormDialog();
	const [extraFilters, rangeValues] = useExtraFilters();
	const { loads, retry, loading } = useFetchData(rangeValues);
	const handleAction = useHandleAction(retry, showLoadForm, showAuditFor);
	const extraColumns = useExtraColumns(handleAction);
	return (
		<>
			<title>Loads</title>
			{auditDialog}
			{loadFormDialog}
			<LoadsGrid
				data={loads}
				extraColumns={extraColumns}
				refresh={retry}
				extraButtons={
					<Button
						icon="plus"
						themeColor="primary"
						onClick={() =>
							showLoadForm({
								onSubmit: () => {
									retry();
									showLoadForm();
								},
							})
						}
					>
						Add Load
					</Button>
				}
				loading={loading}
				extraFilters={extraFilters}
				footer={{
					revenue: (data) => (
						<td>
							{toCurrency(
								sumBy(
									data.filter((item) => item.status !== LoadStatus.Cancelled),
									(x) => x.revenue ?? 0,
								),
							)}
						</td>
					),
					sharedCost: (data) => (
						<td>
							{toCurrency(
								sumBy(
									data.filter((item) => item.status !== LoadStatus.Cancelled),
									(x) => x.sharedCost ?? 0,
								),
							)}
						</td>
					),
					cost: (data) => (
						<td>
							{toCurrency(
								sumBy(
									data.filter((item) => item.status !== LoadStatus.Cancelled),
									(x) => x.cost ?? 0,
								),
							)}
						</td>
					),
				}}
			/>
		</>
	);
};
