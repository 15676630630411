import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useMemo, useState } from "react";
import { type UseFormSetValue, useForm } from "react-hook-form";
import { useDeepCompareEffect } from "react-use";
import {
	type InferType,
	array,
	boolean,
	date,
	number,
	object,
	string,
} from "yup";
import { GLOBALS } from "../_GLOBALS";
import { DFlex } from "../display/DFlex";
import { DGrid } from "../display/DGrid";
import {
	NewLegType,
	legKindOf,
	noInvalidDate,
	noNaN,
	useWatchFormValuesOnChange,
} from "../helpers";
import { useOnAddLocation } from "../helpersReact";
import {
	GenericForm,
	IDateTime,
	IMultiSelect,
	INumber,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";

const baseFields = {
	id: number().nullable().transform(noNaN).label("ID"),
	jobId: number().nullable().transform(noNaN).label("Job"),
	loadId: number().nullable().transform(noNaN).label("Load"),
	legType: number().required().label("Type"),
	goodsIds: array().of(number().required()).required().min(1).label("Goods"),
	notes: string().nullable().label("Notes"),
	supplierInvoiceNumber: string().nullable().label("Supplier Invoice Number"),
	supplierInvoice: number().label("Supplier Invoice Status"),
	supplierInvoiceDate: date()
		.transform(noInvalidDate)
		.label("Supplier Invoice Date"),
	cost: number().nullable().transform(noNaN).label("Cost"),
	currencyId: number().nullable().label("Currency"),
};

const operationFerryCustomsFields = {
	driverId: number().nullable().label("Driver"),
	truckId: number().nullable().label("Truck"),
	trailerId: number().nullable().label("Trailer"),
	subcontractorId: number().nullable().label("Subcontractor"),
	transferBusinessUnitId: string().nullable().label("Business Unit"),
};

const operationFields = {
	truckTypeId: number().nullable().label("Truck Type"),
	trailerTypeId: number().nullable().label("Trailer Type"),
	collectionLocationId: number().nullable().label("Collection Location"),
	collectionDate: date().transform(noInvalidDate).label("Collection Date"),
	deliveryLocationId: number().nullable().label("Delivery Location"),
	deliveryDate: date().transform(noInvalidDate).label("Delivery Date"),
	_isOperationDirty: boolean(),
};

const storageFields = {
	storageLocation: string().nullable().label("Storage Location"),
	storageStartDate: date().transform(noInvalidDate).label("Storage Start Date"),
	storageEndDate: date().transform(noInvalidDate).label("Storage End Date"),
};

const ferryFields = {
	ferryRoute: string().nullable().label("Ferry Route"),
	ferrySailingDate: date().transform(noInvalidDate).label("Ferry Sailing Date"),
	ferryReferenceNumber: string().nullable().label("Ferry Reference Number"),
};

const customsFields = {
	ucr: string().nullable().label("UCR"),
	mrn: string().nullable().label("MRN"),
	t1: string().nullable().label("T1"),
	clearanceLocation: string().nullable().label("Clearance Location"),
	clearanceDate: date().transform(noInvalidDate).label("Clearance Date"),
};

const fields = {
	...baseFields,
	/* operation, ferry, customs */
	...operationFerryCustomsFields,
	/* operation */
	...operationFields,
	/* storage */
	...storageFields,
	/* ferry */
	...ferryFields,
	/* customs */
	...customsFields,
};

const fullLegSchema = object(fields);

const useLegFormSchema = (defaultType?: number) => {
	const [type, setType] = useState<number | undefined>(defaultType);
	let schema: typeof fullLegSchema;
	const kind = legKindOf(type);
	let canHaveRecs = false;
	if (kind === "operation") {
		schema = object({
			...baseFields,
			...operationFerryCustomsFields,
			...operationFields,
		}) as typeof fullLegSchema;
		canHaveRecs = true;
	} else if (kind === "storage") {
		schema = object({
			...baseFields,
			...storageFields,
		}) as typeof fullLegSchema;
	} else if (kind === "ferry") {
		schema = object({
			...baseFields,
			...operationFerryCustomsFields,
			...ferryFields,
		}) as typeof fullLegSchema;
	} else if (kind === "clearCustoms") {
		schema = object({
			...baseFields,
			...operationFerryCustomsFields,
			...customsFields,
		}) as typeof fullLegSchema;
	} else if (kind === "blank") {
		schema = object({
			...baseFields,
		}) as typeof fullLegSchema;
	} else {
		throw new Error(`Unknown leg kind: ${kind}`);
	}
	if (type === NewLegType.Collection || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			collectionLocationId: fields.collectionLocationId.required(),
			collectionDate: fields.collectionDate.required(),
		});
	}
	if (type === NewLegType.Delivery || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			deliveryLocationId: fields.deliveryLocationId.required(),
			deliveryDate: fields.deliveryDate.required(),
		});
	}
	if (type === NewLegType.Blank) {
		schema = schema.shape({
			notes: fields.notes.required(),
		});
	}
	return [
		schema,
		(type: number) => setType(Number(type)),
		canHaveRecs,
	] as const;
};

export type Leg = InferType<typeof fullLegSchema>;
type LegFormOperation = Pick<
	Leg,
	| "driverId"
	| "truckId"
	| "trailerId"
	| "subcontractorId"
	| "transferBusinessUnitId"
>;
type LegFormProps = {
	defaultValues?: Partial<Leg>;
	isRecEnabled?: boolean;
	onRecommendationsButtonClick?: () => void;
	lLegTypes: LoadOptionsFn;
	lGoods: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lSubcontractors: LoadOptionsFn;
	lTruckTypes: LoadOptionsFn;
	lTrailerTypes: LoadOptionsFn;
	lDrivers: LoadOptionsFn;
	lTrucks: LoadOptionsFn;
	lTrailers: LoadOptionsFn;
	lBusinessUnits: LoadOptionsFn;
	lSupplierInvoiceTypes: LoadOptionsFn;
	lCurrencies: LoadOptionsFn;
	onSubmit: (data: Leg) => Promise<unknown>;
	onAddLocation?: (callback: (value: number) => void) => void;
	onChange?: (data: Leg) => void;
	onGoodsIdsChange?: (data: number[], form: UseFormSetValue<Leg>) => void;
	onOperationIdsChange?: (
		data: LegFormOperation,
		formValues: Partial<Leg>,
		setValue: UseFormSetValue<Leg>,
	) => void;
};
export const LegForm = (x: LegFormProps) => {
	const [schema, setType, canHaveRecs] = useLegFormSchema(
		x.defaultValues?.legType,
	);
	const form = useForm<Leg>({
		resolver: yupResolver(schema),
		defaultValues: x.defaultValues,
	});
	const isOperationDirty =
		form.formState.dirtyFields.driverId ||
		form.formState.dirtyFields.truckId ||
		form.formState.dirtyFields.trailerId ||
		form.formState.dirtyFields.subcontractorId;
	useEffect(() => {
		form.setValue("_isOperationDirty", isOperationDirty ?? false);
	}, [isOperationDirty, form.setValue]);
	GLOBALS.legForm = form;
	const formValues = form.watch();
	useDeepCompareEffect(() => {
		x.onChange?.(formValues);
	}, [formValues, x.onChange]);
	useWatchFormValuesOnChange(
		formValues,
		["goodsIds"],
		(value) =>
			form.formState.dirtyFields.goodsIds &&
			x.onGoodsIdsChange?.(value.goodsIds ?? [], form.setValue),
	);
	useWatchFormValuesOnChange(formValues, ["driverId"], (value) =>
		x.onOperationIdsChange?.(value, formValues, form.setValue),
	);
	useWatchFormValuesOnChange(formValues, ["truckId"], (value) =>
		x.onOperationIdsChange?.(value, formValues, form.setValue),
	);
	useWatchFormValuesOnChange(formValues, ["trailerId"], (value) =>
		x.onOperationIdsChange?.(value, formValues, form.setValue),
	);
	useWatchFormValuesOnChange(formValues, ["subcontractorId"], (value) =>
		x.onOperationIdsChange?.(value, formValues, form.setValue),
	);
	const [operationDisabled, businessUnitDisabled] = useMemo(() => {
		let businessUnitDisabled =
			formValues.driverId != null ||
			formValues.truckId != null ||
			formValues.trailerId != null ||
			formValues.subcontractorId != null;
		let operationDisabled = formValues.transferBusinessUnitId != null;
		if (businessUnitDisabled && operationDisabled) {
			operationDisabled = false;
			businessUnitDisabled = false;
		}
		if (operationDisabled) {
			form.setValue("driverId", null);
			form.setValue("truckId", null);
			form.setValue("trailerId", null);
			form.setValue("subcontractorId", null);
		}
		if (businessUnitDisabled) {
			form.setValue("transferBusinessUnitId", null);
		}
		return [operationDisabled, businessUnitDisabled];
	}, [
		formValues.driverId,
		formValues.truckId,
		formValues.trailerId,
		formValues.subcontractorId,
		formValues.transferBusinessUnitId,
		form.setValue,
	]);

	const collectionLocationButton = useOnAddLocation<Leg>(
		"collectionLocationId",
		form.setValue,
		x.onAddLocation,
	);

	const deliveryLocationButton = useOnAddLocation<Leg>(
		"deliveryLocationId",
		form.setValue,
		x.onAddLocation,
	);

	const kind = legKindOf(formValues.legType);
	useEffect(() => {
		setType(formValues.legType);
		form.clearErrors();
	}, [formValues.legType, setType, form.clearErrors]);
	return (
		<GenericForm
			schema={schema}
			form={form}
			onSubmit={(data) => x.onSubmit(schema.cast(data, { stripUnknown: true }))}
			extraButtons={
				x.isRecEnabled &&
				canHaveRecs && (
					<Button
						type="button"
						onClick={() => x.onRecommendationsButtonClick?.()}
					>
						✨ Recommendations
					</Button>
				)
			}
		>
			{x.defaultValues?.id == null && (
				<DGrid>
					<ISelect n="legType" l={x.lLegTypes} noClear />
				</DGrid>
			)}
			{kind && (
				<DFlex>
					<div style={{ width: "100%" }}>
						<IMultiSelect n="goodsIds" l={x.lGoods} />
						{kind === "storage" && (
							<>
								<IText n="storageLocation" />
								<IDateTime n="storageStartDate" />
								<IDateTime n="storageEndDate" />
							</>
						)}
						{kind === "ferry" && (
							<>
								<IText n="ferryRoute" />
								<IDateTime n="ferrySailingDate" />
								<IText n="ferryReferenceNumber" />
							</>
						)}
						{kind === "clearCustoms" && (
							<>
								<IText n="ucr" />
								<IText n="mrn" />
								<IText n="t1" />
								<IText n="clearanceLocation" />
								<IDateTime n="clearanceDate" />
							</>
						)}
						{kind === "operation" && (
							<>
								<ISelect
									n="collectionLocationId"
									l={x.lLocations}
									label={
										<>
											Collection Location
											{collectionLocationButton}
										</>
									}
								/>
								<IDateTime n="collectionDate" />
								<ISelect
									n="deliveryLocationId"
									l={x.lLocations}
									label={
										<>
											Delivery Location
											{deliveryLocationButton}
										</>
									}
								/>
								<IDateTime n="deliveryDate" />
							</>
						)}
					</div>
					{kind !== "blank" && kind !== "storage" && (
						<div style={{ minWidth: "184px", width: "100%" }}>
							{kind === "operation" && (
								<>
									<ISelect n="truckTypeId" l={x.lTruckTypes} />
									<ISelect n="trailerTypeId" l={x.lTrailerTypes} />
								</>
							)}
							<ISelect
								n="driverId"
								l={x.lDrivers}
								disabled={operationDisabled}
							/>
							<ISelect n="truckId" l={x.lTrucks} disabled={operationDisabled} />
							<ISelect
								n="trailerId"
								l={x.lTrailers}
								disabled={operationDisabled}
							/>
							<ISelect
								n="subcontractorId"
								l={x.lSubcontractors}
								disabled={operationDisabled}
							/>
							<ISelect
								n="transferBusinessUnitId"
								l={x.lBusinessUnits}
								disabled={businessUnitDisabled}
							/>
						</div>
					)}
					<div style={{ width: "100%" }}>
						<DGrid columns="1fr .8fr">
							<INumber n="cost" step={0.01} />
							<ISelect n="currencyId" l={x.lCurrencies} />
						</DGrid>
						<IText n="supplierInvoiceNumber" />
						<ISelect n="supplierInvoice" l={x.lSupplierInvoiceTypes} />
						<IDateTime n="supplierInvoiceDate" />
						<ITextArea n="notes" />
					</div>
				</DFlex>
			)}
		</GenericForm>
	);
};
